// This code creates an Axios instance with withCredentials set to true,
// which is necessary for including CSRF tokens in requests.
// It sets a base URL for all requests.
// An interceptor is added to inject the CSRF token from cookies into the request headers for every outgoing request.
// This ensures CSRF protection for your API requests.

import axios, { AxiosInstance } from 'axios';
import { getJwtToken } from './apiWebToken';

const addJwtToken = (config: any) => {
  const jwtToken = getJwtToken();
  if (jwtToken && config.headers) {
    config.headers['Authorization'] = `Bearer ${jwtToken}`;
  }
};

const addCsrfToken = (config: any) => {
  const csrfTokenCookie = document.cookie.split('; ').find((row) => row.startsWith('XSRF-TOKEN='));
  if (csrfTokenCookie && config.headers) {
    const csrfToken = decodeURIComponent(csrfTokenCookie.split('=')[1]);
    config.headers['X-XSRF-TOKEN'] = csrfToken;
  }
};

// Set up for axiosClassic instance
const classicBaseURL = process.env.NODE_ENV === 'production' ? `${import.meta.env.VITE_LEVEL_TECH_API_URL}/api/classic/` : `/api/classic/`;
const axiosClassic: AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: classicBaseURL,
});

axiosClassic.interceptors.request.use(
  (config) => {
    addCsrfToken(config);
    addJwtToken(config);
    config.url = config.url!.replace('.php', '');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosClassic.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Set up for axiosLevelTech instance
const baseURL = process.env.NODE_ENV === 'production' ? import.meta.env.VITE_LEVEL_TECH_API_URL : import.meta.env.VITE_LOCALHOST_URL;
const axiosLevelTech: AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
});

axiosLevelTech.interceptors.request.use(
  (config) => {
    addCsrfToken(config);
    addJwtToken(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosLevelTech.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { axiosClassic, axiosLevelTech };
