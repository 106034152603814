import axios from 'axios';
import { useUserDataStore } from '@/store/userDataStore';

class ClickTrackerService {
  private commsKey: string | undefined;
  private personId: string | undefined;
  private isInitialized = false; // Flag to prevent multiple event listener attachments

  constructor() {
    this.initializeService();
  }

  private async initializeService() {
    try {
      await this.waitForData();
      this.init();
    } catch (error) {
      console.error('Initialization failed:', error);
    }
  }

  private waitForData(): Promise<void> {
    return new Promise((resolve, reject) => {
      const userDataStore = useUserDataStore();
      const startTime = Date.now();

      const checkDataAvailability = () => {
        if (userDataStore.jsdefs.commsKey && userDataStore.jsdefs.personId !== undefined) {
          this.commsKey = userDataStore.jsdefs.commsKey;
          this.personId = userDataStore.jsdefs.personId;
          resolve();
        } else if (Date.now() - startTime > 60000) {
          console.error('Timeout: Data not available after 1 minute.');
          reject('Data not available after 1 minute');
        } else {
          setTimeout(checkDataAvailability, 100);
        }
      };

      checkDataAvailability();
    });
  }

  public init() {
    if (this.isInitialized) return; // Prevent multiple initializations
    this.isInitialized = true;

    document.addEventListener('click', this.handleDocumentClick.bind(this));

    // console.groupCollapsed('ClickTrackerService Initialization');
    // console.log('Click tracker initialized.');
    // console.log('commsKey:', this.commsKey);
    // console.log('personId:', this.personId);
    // console.groupEnd();
  }

  private handleDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    const anchorElement = target.closest('a');
    const formElement = target.closest('form');

    let url = '';
    let action = '';

    if (anchorElement) {
      url = (anchorElement as HTMLAnchorElement).href;
      action = url.startsWith('http') ? 'external_link' : 'internal_link';
    } else if (formElement) {
      url = (formElement as HTMLFormElement).action;
      if (url.startsWith('http')) {
        action = 'external_link';
      }
    }

    if (url && action) {
      // console.groupCollapsed('Click Event Tracking');
      this.recordLinkClick(url, action);
      // console.groupEnd();
    }
  }

  private recordLinkClick(url: string, action: string) {
    const timeNow = Math.round(new Date().getTime() / 1000);
    const params = {
      action: action,
      playerid: this.personId,
      time: timeNow,
      link: encodeURIComponent(url),
      key: this.commsKey,
    };

    // console.log('JsDefs Values - commsKey:', this.commsKey, 'personId:', this.personId);
    // console.log('Click Event');
    // console.log('URL:', url);
    // console.log('Action Parameter:', action);
    // console.log('PlayerID Parameter:', this.personId);
    // console.log('Time Parameter:', timeNow);
    // console.log('Link Parameter (decoded):', decodeURIComponent(encodeURIComponent(url)));
    // console.log('Key Parameter:', this.commsKey);
    // console.log('Data Sent to Endpoint:', params);

    axios
      .get('info.php', { params, timeout: 3000 })
      .then(() => {
        // console.log(`${action === 'external_link' ? 'External' : 'Internal'} link tracked successfully:`, url);
      })
      .catch((error) => {
        console.error(`Error tracking ${action === 'external_link' ? 'external' : 'internal'} link:`, error);
      });
  }
}

export default ClickTrackerService;
