/**
 * plugins/webfontloader.ts
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export async function loadFonts() {
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */ 'webfontloader');

  webFontLoader.load({
    google: {
      families: ['Roboto:100,300,400,500,700,900&display=swap'],
    },
    custom: {
      families: [
        'mongoose', // $mongoose-font
        'Brandon Grotesque', // $brandon-grotesque-font
        'english-grotesque', // $english-grotesque-font
      ],
      urls: [
        'https://use.typekit.net/wvr4bdh.css',
        'https://fonts.cdnfonts.com/css/grotesque',
      ],
    },
  });
}