<template>
  <router-view />
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, onBeforeUnmount } from 'vue';

// ----------------------------------------------------------------------------------
// Code to look for class name of 'help_icon` and scroll to top of page when clicked'

// Helper function to check if an element or its parent has a specific class
const hasClass = (element: HTMLElement, className: string): boolean => {
  while (element) {
    if (element.classList.contains(className)) {
      return true;
    }
    element = element.parentElement as HTMLElement;
  }
  return false;
};

// Function to handle the click event globally
const handleClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (hasClass(target, 'help_icon')) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
  }
};

// Setup and teardown of the global event listener
onMounted(() => {
  document.addEventListener('click', handleClick);
});
onUnmounted(() => {
  document.removeEventListener('click', handleClick);
});
// End
</script>

<style lang="scss">
@import '@/styles/tech_variables.scss';

/* ---------------------------------------------------------------------------------- */
// Generic Body Styling Declarations (Luke McFarland)

// Set the body background color to light grey
body {
  background: $light-grey;
}

// Temporary Code to Set Site Body Color to Light Grey
.v-main {
  background-color: $light-grey;
}

// Temporart Code to Set Site Body To Display As Flex Column
.v-layout {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

// Temporary Code to Hide = header, header_placeholder, desktop_sitemap, desktop_social_media, copyright_banner and notifications banners.
#header,
#header_placeholder,
#desktop_sitemap,
#desktop_social_media,
#copyright_banner,
.notification_div,
#bottom_menu_header_placeholder {
  display: none !important;
}

// Temporary Code to Change Background Color
.v-main,
.light_background,
input[type='email'],
#whole_page,
#maya_content_container,
#bottom_menu {
  background-color: $tech_background_color !important;
}

// Temporary code to set the height of the external content to be 100% of the viewport height minus the height of the header and bottom menu
// This stops the "glitch" reported by Jethro
#external-content {
  min-height: calc(100vh - 64px - 100px);
}

// Temporary code to set the height of the maya container to 100% on tablet screens or larger
@media (min-width: $tablet-screen) {
  #maya {
    height: 100% !important;
  }
}
@media (max-width: $tablet-screen) {
  #maya {
    height: unset;
  }
}

// Add Margin to the bottom of the Maya Content Container to prevent it being hidden behind the mobile menu
@media (max-width: $mobile-screen) {
  #maya_content_container {
    margin-bottom: 120px;
  }
}
</style>
