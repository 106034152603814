// Fetches CSRF token using Axios; sets it in a cookie for secure API requests.
// Includes error handling.

import { axiosLevelTech } from './axiosConfig';

export async function fetchCsrfToken() {
    try {
        const response = await axiosLevelTech.get('/sanctum/csrf-cookie');
        // console.groupCollapsed('CSRF Token Fetch');
        // console.log('Fetched:', response);
        // console.groupEnd();
    } catch (error) {
        // console.groupCollapsed('Error Fetching CSRF Token');
        // console.error(error);
        // console.groupEnd();
        // Consider additional error handling strategies here, like retrying the fetch
    }
}

export async function initializeApiServices() {
    try {
        await fetchCsrfToken();
        // Add any additional API initialization tasks here
    } catch (error) {
        // console.groupCollapsed('Error Initializing API Services');
        // console.error(error);
        // console.groupEnd();
        // Consider how to propagate this error to the user or application
    }
}