import { defineStore } from 'pinia';

// Interface for the JWT payload. Define new fields here with appropriate types.
interface JwtPayload {
  exp?: number; // Token expiration time, mandatory for expiry checks
  iat?: number; // Issued at time, optional
  nbf?: number; // Not before time, optional
  iss?: string; // Issuer of the token, optional
  userName?: string; // Custom field for user name
  personId?: string; // Custom field for person ID
  userInfo?: {
    membership_tiers: {
      is_visitor: boolean;
      is_basic: boolean;
      is_generic: boolean;
      is_ngb: boolean;
      is_trial: boolean;
      is_gold: boolean;
      is_platinum: boolean;
      is_vip: boolean;
      is_developer: boolean;
      is_admin: boolean;
      is_club_admin: boolean;
      is_county_admin: boolean;
      is_results_admin: boolean;
      is_premium: boolean;
    };
  };
}

// User info interface matching the structure expected in the state
export interface UserInfo {
  email: string; // The email will be fetched from userName in JwtPayload
  personId: string;
  membership_tiers: {
    is_visitor: boolean;
    is_basic: boolean;
    is_generic: boolean;
    is_ngb: boolean;
    is_trial: boolean;
    is_gold: boolean;
    is_platinum: boolean;
    is_vip: boolean;
    is_developer: boolean;
    is_admin: boolean;
    is_club_admin: boolean;
    is_county_admin: boolean;
    is_results_admin: boolean;
    is_premium: boolean;
  };
}

// Interface defining the state structure in the store
interface UserState {
  userInfo: UserInfo | null; // Currently logged-in user's info or null if no user
  token: string | undefined; // JWT token or undefined if not fetched yet
  isTokenExpired: boolean; // True if the token has expired, false otherwise
}

// Definition of the user store using Pinia
export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => ({
    userInfo: null,
    token: undefined,
    isTokenExpired: false,
  }),
  getters: {
    isLoggedIn: (state): boolean => !!state.userInfo && !state.isTokenExpired,
    isLoggedOut: (state): boolean => !state.userInfo || state.isTokenExpired,
    hasAccess(): (allowedRoles: (keyof UserInfo['membership_tiers'])[]) => boolean {
      return (allowedRoles: (keyof UserInfo['membership_tiers'])[]) => {
        // console.groupCollapsed('Checking Access Permissions via Getter');
        if (!this.userInfo || !this.userInfo.membership_tiers) {
          // console.log('No user info or membership tiers available.');
          // console.groupEnd();
          return false;
        }

        const roleCheck = (role: keyof UserInfo['membership_tiers']) => this.userInfo!.membership_tiers[role];
        const hasAccess = allowedRoles.some(roleCheck);

        // console.log('Final Access Decision:', hasAccess);
        // console.groupEnd();
        return hasAccess;
      };
    },
  },
  actions: {
    fetchToken(token: string = '') {
      // console.groupCollapsed('JWT Token Fetching');
      this.token = token;
      if (token) {
        localStorage.setItem('tokenV1', token);
        this.decodeToken(token);
        // console.log('Token Set:', token);
      } else {
        this.isTokenExpired = true;
        localStorage.removeItem('tokenV1');
        // console.log('User is Logged Out (No token)');
      }
      // console.groupEnd();
    },

    decodeToken(token: string) {
      // console.groupCollapsed('JWT Token Processing');
      try {
        const user = this.extractUserFromJWT(token);
        // console.log('Decoded Token:', user);

        if (user && user.exp && Date.now() >= user.exp * 1000) {
          console.warn('Token has expired');
          this.isTokenExpired = true;
          this.userInfo = null;
          localStorage.removeItem('tokenV1');
        } else if (user) {
          this.isTokenExpired = false;
          this.userInfo = {
            email: user.userName || '',
            personId: user.personId || '',
            membership_tiers: user.userInfo?.membership_tiers!,
          };
          // console.log('User Info Set:', JSON.stringify(this.userInfo, null, 2));
        } else {
          console.warn('Invalid token');
          this.isTokenExpired = true;
          this.userInfo = null;
          localStorage.removeItem('tokenV1');
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        this.isTokenExpired = true;
        this.userInfo = null;
        localStorage.removeItem('tokenV1');
      }
      // console.groupEnd();
    },

    extractUserFromJWT(token: string): JwtPayload | null {
      try {
        const payload = token.split('.')[1];
        const decodedPayload = atob(payload);
        return JSON.parse(decodedPayload);
      } catch {
        return null;
      }
    },

    initializeStore() {
      const token = localStorage.getItem('tokenV1');
      if (token) {
        // console.groupCollapsed('Initializing Store with token');
        this.fetchToken(token);
        // console.groupEnd();
      } else {
        console.log('No token found in localStorage, store not initialized');
      }
    },
  },
});
