/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 *
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify, type ThemeDefinition } from 'vuetify';

export const light: ThemeDefinition = {
  dark: false,
  colors: {
    // legacy colours
    primary: '#E34891', // define("HOT_PINK", "#E34891");
    secondary: '#EB3D61', // define("RED_PINK", "#EB3D61");
    // info: '#43A5EE', // define("BLUE", "#43A5EE");
    // success: '#7DE3C2', // define("TEAL", "#7DE3C2");
    // warning: '#FFCA3B', // define("YELLOW", "#FFCA3B");
    // error: '#FF5151', // define("RED", "#FF5151");
    'muted-purple': '#685F76', // define("MUTED_PURPLE", "#685F76");
    'site-purple': '#372758', // define("SITE_PURPLE", "#372758");
    purple: '#3A255B', // define("PURPLE", "#3A255B");
    'dark-purple': '#301C50', // define("DARK_PURPLE", "#301C50");
    'light-grey': '#EFEFEF', // define("LIGHT_GREY", "#EFEFEF");
    'mid-grey': '#E5E5E5', // define("MID_GREY", "#E5E5E5");
    'dark-grey': '#DBDBDB', // define("DARK_GREY", "#DBDBDB");
    'darker-grey': '#D1D1D1', // define("Darker_GREY", "#D1D1D1");
    blueberry: '#4B3076', // define("BLUEBERRY", "#4B3076");
    yellow: '#FFCA3B', // define("YELLOW", "#FFCA3B");
    red: '#FF5151', // define("RED", "#FF5151");
    tech_background_color: '#f5f5f9', // define("TECH_SITE_BACKGROUND", "#f5f5f9");
    // theme defaults found in all sports

    white: '#FFFFFF', // define("WHITE", "#FFFFFF");
    black: '#000000', // Black Color
    'grey-100': '#efefef',
    'grey-200': '#e5e5e5',
    'grey-300': '#DDDDDD',
    'grey-400': '#bfbdbd',
    'grey-500': '#8d8c8c',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',
    grey: '#efefef', // grey-100
    info: '#43A5EE', // blue-500
    'info-blue-300': '#a6d9ff',
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    error: '#EB3D61', // red-500
    'err-red-500': '#ff485D',
    'yellow-100': '#fffcf3',
    'yellow-200': '#fff0c8',
    'yellow-300': '#ffe08c',
    'yellow-400': '#ffd666',
    'yellow-500': '#ffca3b',
    'yellow-600': '#ffc014',
    'yellow-700': '#ffaf14',
    'yellow-800': '#ff9914',
    'yellow-900': '#d87a00',
    'red-100': '#ffe1e1',
    'red-200': '#ffa1a1',
    'red-300': '#f26e6e',
    'red-400': '#e55050',
    'red-500': '#d83232',
    'red-600': '#c41e1e',
    'red-700': '#a51717',
    'red-800': '#7f0909',
    'red-900': '#4e0202',
  },
};
export const squashTheme: ThemeDefinition = {
  dark: false,
  colors: {
    white: '#FFFFFF', // define("WHITE", "#FFFFFF");
    black: '#000000', // Black Color
    'grey-100': '#efefef',
    'grey-200': '#e5e5e5',
    'grey-300': '#DDDDDD',
    'grey-400': '#bfbdbd',
    'grey-500': '#8d8c8c',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',
    grey: '#efefef', // grey-100
    info: '#43A5EE', // blue-500
    'info-blue-300': '#a6d9ff',
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    error: '#EB3D61', // red-500
    'err-red-500': '#ff485D',
    'yellow-100': '#fffcf3',
    'yellow-200': '#fff0c8',
    'yellow-300': '#ffe08c',
    'yellow-400': '#ffd666',
    'yellow-500': '#ffca3b',
    'yellow-600': '#ffc014',
    'yellow-700': '#ffaf14',
    'yellow-800': '#ff9914',
    'yellow-900': '#d87a00',
    'red-100': '#ffe1e1',
    'red-200': '#ffa1a1',
    'red-300': '#f26e6e',
    'red-400': '#e55050',
    'red-500': '#d83232',
    'red-600': '#c41e1e',
    'red-700': '#a51717',
    'red-800': '#7f0909',
    'red-900': '#4e0202',
    'purple-100': '#c8badf',
    'purple-200': '#6b568d',
    'purple-300': '#563c80',
    'purple-400': '#4a2f74',
    'purple-500': '#3a255b',
    'purple-600': '#331d55',
    'purple-700': '#281545',
    'purple-800': '#1c093a',
    'purple-900': '#120329',
    'dark-purple': '#301C50', // define("DARK_PURPLE", "#301C50");
    'pink-100': '#ffdaeb',
    'pink-200': '#ffb2d6',
    'pink-300': '#fe88bf',
    'pink-400': '#ff6ab0',
    'pink-500': '#ff3795',
    'pink-600': '#fd1b85',
    'pink-700': '#eb016f',
    'pink-800': '#b40155',
    'pink-900': '#4b0023',
    'teal-100': '#e8f3f0',
    'teal-200': '#c6ebe1',
    'teal-300': '#a5f0dc',
    'teal-400': '#74f2d1',
    'teal-500': '#4ee7c0',
    'teal-600': '#34d8ae',
    'teal-700': '#27bb95',
    'teal-800': '#1e9073',
    'teal-900': '#0f4739',
    teal: '#7de3c2',
    'blue-500': '#43a5ee',
    'green-500': '#6ad26a',
    'site-800': '#1c093a',
    primary: '#3a255b', // purple-500
    'site-primary': '#3a255b', // purple-500
    'site-dark': '#331d55', // purple-500
    secondary: '#ff3795', // pink-500
    'site-secondary': '#ff3795', // pink-500
    tertiary: '#4ee7c0', // teal-500
    highlight: '#4ee7c0', // teal-500
    quaternary: '#FFCA3B', // yellow-500
    action: '#FFCA3B', // yellow-500
    'site-primary-hover': '#4a2f74', // purple-400
    'site-secondary-hover': '#eb016f', // pink-700
    'highlight-hover': '#74f2d1', // teal-400
    'action-hover': '#ffd666', // yellow-400
    'grey-hover': '#e5e5e5', // grey-200
    'site-primary-focus': '#ff3795', // purple-400
    'site-secondary-focus': '#ff3795', // pink-700
    'highlight-focus': '#3a255b', // teal-400
    'action-focus': '#ff3795', // yellow-400
    'grey-focus': '#ff3795', // grey-200
    'site-primary-text': '#FFFFFF', // purple-400
    'site-secondary-text': '#3a255b', // pink-700
    'highlight-text': '#3a255b', // teal-400
    'action-text': '#3a255b', // yellow-400
    'input-label': '#ff3795', // yellow-400
    'input-hover': '#4ee7c0', // yellow-400
    'input-focus': '#ff3795',
    'grey-text': '#3a255b',
    scrollbar: '#6b568d',
  },
};
export const padelTheme: ThemeDefinition = {
  dark: false,
  colors: {
    white: '#FFFFFF', // define("WHITE", "#FFFFFF");
    black: '#000000', // Black Color
    'grey-100': '#efefef',
    'grey-200': '#e5e5e5',
    'grey-300': '#DDDDDD',
    'grey-400': '#bfbdbd',
    'grey-500': '#8d8c8c',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',
    grey: '#efefef', // grey-100
    info: '#43A5EE', // blue-500
    'info-blue-300': '#a6d9ff',
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    // error: '#EB3D61', // red-500
    'err-red-500': '#ff485D',
    'yellow-100': '#fffcf3',
    'yellow-200': '#fff0c8',
    'yellow-300': '#ffe08c',
    'yellow-400': '#ffd666',
    'yellow-500': '#ffca3b',
    'yellow-600': '#ffc014',
    'yellow-700': '#ffaf14',
    'yellow-800': '#ff9914',
    'yellow-900': '#d87a00',
    'red-100': '#ffe1e1',
    'red-200': '#ffa1a1',
    'red-300': '#f26e6e',
    'red-400': '#e55050',
    'red-500': '#d83232',
    'red-600': '#c41e1e',
    'red-700': '#a51717',
    'red-800': '#7f0909',
    'red-900': '#4e0202',
    'navy-100': '#cbcfff',
    'navy-200': '#6f74b2',
    'navy-300': '#313780',
    'navy-400': '#19184c',
    'navy-500': '#0d0c38',
    'navy-600': '#090830',
    'navy-700': '#060529',
    'navy-800': '#03021a',
    'navy-900': '#010110',

    'blue-100': '#e1e7ff',
    'blue-200': '#9eb2ff',
    'blue-300': '#6b8aff',
    'blue-400': '#456cff',
    'blue-500': '#2250ff',
    'blue-600': '#0e3ae5',
    'blue-700': '#0a2ebd',
    'blue-800': '#001d90',
    'blue-900': '#021358',
    teal: '#7de3c2',
    purple: '#372758',
    'dark-purple': '#301c50',
    'hot-pink': '#eb4d93',
    'site-800': '#03021a',
    primary: '#0d0c38', // purple-500
    'site-primary': '#0d0c38', // purple-500
    'site-dark': '#090830', // purple-500
    secondary: '#d83232', // teal-500
    'site-secondary': '#d83232', // teal-500
    tertiary: '#2250ff', // pink-500
    highlight: '#2250ff', // pink-500
    quaternary: '#FFCA3B', // yellow-500
    action: '#FFCA3B', // yellow-500
    error: '#ff485d', // red-500
    'site-primary-hover': '#19184c', // purple-400
    'site-secondary-hover': '#a51717', // teal-400
    'highlight-hover': '#456cff', // pink-700
    'action-hover': '#ffd666', // yellow-400
    'grey-hover': '#e5e5e5', // grey-200
    'site-primary-focus': '#6f74b2', // purple-400
    'site-secondary-focus': '#ffa1a1', // teal-400
    'highlight-focus': '#9eb2ff', // pink-700
    'action-focus': '#fff0c8', // yellow-400
    'grey-focus': '#DDDDDD', // grey-
    'site-primary-text': '#FFFFFF', // purple-400
    'site-secondary-text': '#FFFFFF', // pink-700
    'highlight-text': '#FFFFFF', // teal-400
    'action-text': '#0d0c38', // yellow-400
    'grey-text': '#0d0c38', // yellow
    'input-label': '#d83232', // yellow-400
    'input-hover': '#2250ff', // yellow-400
    'input-focus': '#0d0c38', // yellow-400
    scrollbar: '#6f74b2',
  },
};

export const racketballTheme: ThemeDefinition = {
  dark: false,
  colors: {
    white: '#FFFFFF', // define("WHITE", "#FFFFFF");
    black: '#000000', // Black Color
    'grey-100': '#efefef',
    'grey-200': '#e5e5e5',
    'grey-300': '#DDDDDD',
    'grey-400': '#bfbdbd',
    'grey-500': '#8d8c8c',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',
    grey: '#efefef', // grey-100
    info: '#43A5EE', // blue-500
    'info-blue-300': '#a6d9ff',
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    // error: '#EB3D61', // red-500
    'err-red-500': '#ff485D',
    'yellow-100': '#fffcf3',
    'yellow-200': '#fff0c8',
    'yellow-300': '#ffe08c',
    'yellow-400': '#ffd666',
    'yellow-500': '#ffca3b',
    'yellow-600': '#ffc014',
    'yellow-700': '#ffaf14',
    'yellow-800': '#ff9914',
    'yellow-900': '#d87a00',
    'red-100': '#ffe1e1',
    'red-200': '#ffa1a1',
    'red-300': '#f26e6e',
    'red-400': '#e55050',
    'red-500': '#d83232',
    'red-600': '#c41e1e',
    'red-700': '#a51717',
    'red-800': '#7f0909',
    'red-900': '#4e0202',
    'navy-100': '#315693',
    'navy-200': '#213F7C',
    'navy-300': '#1B326B',
    'navy-400': '#1A2B60',
    'navy-500': '#172456',
    'navy-600': '#131F47',
    'navy-700': '#0F1A38',
    'navy-800': '#0C162D',
    'navy-900': '#070F1E',

    'pink-100': '#FFBDE1',
    'pink-200': '#FC90C7',
    'pink-300': '#FF73B3',
    'pink-400': '#FF5FA0',
    'pink-500': '#F85596',
    'pink-600': '#E54A89',
    'pink-700': '#CE3A7D',
    'pink-800': '#A52163',
    'pink-900': '#771149',

    primary: '#172456', // purple-500
    'site-primary': '#172456', // purple-500
    'site-dark': '#131F47', // purple-500
    secondary: '#D83232', // teal-500
    'site-secondary': '#d83232', // teal-500
    tertiary: '#F85596', // pink-500
    highlight: '#F85596', // pink-500
    quaternary: '#FFCA3B', // yellow-500
    action: '#FFCA3B', // yellow-500
    error: '#ff485d', // red-500
    'site-primary-hover': '#1A2B60', // purple-400
    'site-secondary-hover': '#a51717', // teal-400
    'highlight-hover': '#456cff', // pink-700
    'action-hover': '#ffd666', // yellow-400
    'grey-hover': '#e5e5e5', // grey-200
    'site-primary-focus': '#172456', // purple-400
    'site-secondary-focus': '#FC90C7', // teal-400
    'highlight-focus': '#9eb2ff', // pink-700
    'action-focus': '#fff0c8', // yellow-400
    'grey-focus': '#DDDDDD', // grey-
    'site-primary-text': '#FFFFFF', // purple-400
    'site-secondary-text': '#FFFFFF', // pink-700
    'highlight-text': '#FFFFFF', // teal-400
    'action-text': '#172456', // yellow-400
    'grey-text': '#172456', // yellow
    'input-label': '#d83232', // yellow-400
    'input-hover': '#F85596', // yellow-400
    'input-focus': '#172456', // yellow-400
    scrollbar: '#213F7C',
  },
};

export const tennisTheme: ThemeDefinition = {
  dark: false,
  colors: {
    white: '#FFFFFF', // define("WHITE", "#FFFFFF");
    black: '#000000', // Black Color
    'grey-100': '#efefef',
    'grey-200': '#e5e5e5',
    'grey-300': '#DDDDDD',
    'grey-400': '#bfbdbd',
    'grey-500': '#8d8c8c',
    'grey-600': '#646464',
    'grey-700': '#363636',
    'grey-800': '#282828',
    'grey-900': '#181818',
    grey: '#efefef', // grey-100
    info: '#43A5EE', // blue-500
    'info-blue-300': '#a6d9ff',
    success: '#6AD26A', // green-500
    warning: '#FFCA3B', // yellow-500
    // error: '#EB3D61', // red-500
    'err-red-500': '#ff485D',
    'yellow-100': '#fffcf3',
    'yellow-200': '#fff0c8',
    'yellow-300': '#ffe08c',
    'yellow-400': '#ffd666',
    'yellow-500': '#ffca3b',
    'yellow-600': '#ffc014',
    'yellow-700': '#ffaf14',
    'yellow-800': '#ff9914',
    'yellow-900': '#d87a00',

    'green-500': '#344737',
    'orange-500': '#e26139',
    'lime-500': '#e4ff22',

    'green-100': '#687269',
    'green-200': '#566357',
    'green-300': '#455646',
    'green-400': '#394c3b',
    'green-600': '#2c3a2e',
    'green-700': '#242d25',
    'green-800': '#1d231e',
    'green-900': '#141614',
    'orange-100': '#ffc7c0',
    'orange-200': '#ff9280',
    'orange-300': '#ff795a',
    'orange-400': '#f26944',
    'orange-600': '#d34e2a',
    'orange-700': '#b73718',
    'orange-800': '#a3260d',
    'orange-900': '#771201',
    'lime-100': '#fffedc',
    'lime-200': '#ffffa9',
    'lime-300': '#fcff7b',
    'lime-400': '#f3ff50',
    'lime-600': '#d8e803',
    'lime-700': '#c1c600',
    'lime-800': '#a0a000',
    'lime-900': '#6d6a00',

    primary: '#344737', // purple-500
    'site-primary': '#344737', // purple-500
    'site-dark': '#2c3a2e', // purple-500
    secondary: '#e26139', // teal-500
    'site-secondary': '#e26139', // teal-500
    tertiary: '#e4ff22', // pink-500
    highlight: '#e4ff22', // pink-500
    quaternary: '#FFCA3B', // yellow-500
    action: '#FFCA3B', // yellow-500
    error: '#ff485d', // red-500
    'site-primary-hover': '#394c3b', // purple-400
    'site-secondary-hover': '#b73718', // teal-400
    'highlight-hover': '#f3ff50', // pink-700
    'action-hover': '#ffd666', // yellow-400
    'grey-hover': '#e5e5e5', // grey-200
    'site-primary-focus': '#344737', // purple-400
    'site-secondary-focus': '#FC90C7', // teal-400
    'highlight-focus': '#9eb2ff', // pink-700
    'action-focus': '#fff0c8', // yellow-400
    'grey-focus': '#DDDDDD', // grey-
    'site-primary-text': '#FFFFFF', // purple-400
    'site-secondary-text': '#FFFFFF', // pink-700
    'highlight-text': '#1d231e', // teal-400
    'action-text': '#1d231e', // yellow-400
    'grey-text': '#1d231e', // yellow
    'input-label': '#344737', // yellow-400
    'input-hover': '#e4ff22', // yellow-400
    'input-focus': '#344737', // yellow-400
    scrollbar: '#566357',
  },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light,
      squashTheme,
      padelTheme,
      racketballTheme,
      tennisTheme,
    },
  },
});
