import { createPinia } from 'pinia';
import { useUserStore } from './userJWTStore'; // Import the JWT user store

// Create and export the Pinia store instance
const pinia = createPinia();

// Initialize user store and load the JWT token
const userStore = useUserStore(pinia);
userStore.initializeStore(); // Ensuring the token is fetched when the app starts

export default pinia;
