// apiWebToken.ts

/**
 * Extracts the JWT token from the browser cookies.
 * @returns The JWT token if found, otherwise undefined.
 */
const getJwtToken = (): string | undefined => {
    const jwtTokenCookie = document.cookie.split('; ').find(row => row.startsWith('SquashlevelsJWT='));
    
    if (jwtTokenCookie) {
        const jwtToken = decodeURIComponent(jwtTokenCookie.split('=')[1]);
        return jwtToken;
    }
    
    return undefined;
};

export { getJwtToken };
